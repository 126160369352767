import React, { useState } from 'react';
import { Grid, Hidden, Link, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

import { EmailForm, PasswordForm, TextForm } from 'components/Items/Form';
import { LoginSubmitButton } from 'components/Items/Button';
import { FormModal, AlertModal } from 'components/Items/Modal';
import LOGO from 'images/logo.png';
import LOGINBACKGROUNDIMAGE from 'images/loing_background_image.png';


const LoginView = (props) => {
    const { search, setSearch, openPasswordFind, setOpenPasswordFind, searching } = props;
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [openEmailFind, setOpenEmailFind] = useState(false);

    const handleChangeEmail = (e) => {
        props.setUser({ ...props.user, Email: e.target.value });
    };
    const handleChangePassword = (e) => {
        props.setUser({ ...props.user, Password: e.target.value });
    };

    const handleCloseFindEmailModal = () => {
        setOpenEmailFind(false);
        setSearch({ Name: '', Email: '', Phone: '' });
        props.setSearchEmail('');
    };

    const handleCloseFindPasswordModal = () => {
        setOpenPasswordFind(false);
        setSearch({ Name: '', Email: '', Phone: '' });
    };

    const handleChangePhone = (e) => {
        let tump = e.target.value.replaceAll('-', '');
        tump = tump.replace(/[\a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // eslint-disable-line
        tump = tump.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi, ''); // eslint-disable-line
        if(tump.length < 12){
            if(tump.length === 9){
                tump = tump.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 10){
                tump = tump.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }else if(tump.length === 11){
                tump = tump.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/\-{1,2}$/g, ''); // eslint-disable-line
            }
            setSearch({ ...search, Phone: tump });
        }else{
            return false;
        }
    };

    const landingpage = () => {
        window.open('http://info.maumsensor.com');
    };

    return (
        <>
            <Grid
                container
                justifyContent={'flex-start'}
                alignItems={'center'}
                style={{
                    minHeight: '100vh',
                    paddingLeft: '3%',
                    paddingRight: '3%',
                    position: 'relative',
                    zIndex: '2',
                    backgroundColor: '#FAFAFA',
                    backgroundImage: `url("${LOGINBACKGROUNDIMAGE}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: '100%',
                    backgroundPositionX: '80%'
                }}
            >
                <Grid
                    xs={12} sm={12} md={6} lg={5} xl={4}
                    item
                    style={{
                        backgroundColor: '#FFF',
                        borderRadius: '24px',
                        padding: '60px',
                        position: 'relative',
                    }}
                >
                    <Grid style={{ position: 'absolute', top: '60px', left: '60px' }}>
                        <img alt='logo' src={LOGO} width={100}/>
                    </Grid>
                    <p style={{ textAlign: 'center', fontSize: '25px', marginTop: '100px' }}>로그인</p>
                    <EmailForm
                        label='이메일'
                        error={false}
                        value={props.user.Email}
                        handleChange={handleChangeEmail}
                        login={true}
                    />
                    <PasswordForm
                        label='비밀번호'
                        error={false}
                        showPassword={showPassword}
                        handleClick={() => setShowPassword(!showPassword)}
                        value={props.user.Password}
                        handleChange={handleChangePassword}
                        handlePressEnter={props.handleClickLoginBtn}
                        login={true}
                    />
                    <p></p>
                    <LoginSubmitButton
                        label='로그인'
                        loading={props.loading}
                        handleClick={props.handleClickLoginBtn}
                    />
                    <Grid container justifyContent={'space-between'} style={{ fontSize: '12px', marginBottom: '40px' }}>
                        <Grid item>
                            <Link onClick={() => setOpenEmailFind(true)}>이메일</Link>&nbsp;·&nbsp;
                            <Link onClick={() => setOpenPasswordFind(true)}>비밀번호</Link>&nbsp;찾기
                        </Grid>
                        <Grid item>
                            <Link onClick={() => navigate('/register')}>회원 가입</Link>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '20px' }}>
                        <Grid item xs={4}><hr style={{ borderColor: 'rgba(102, 102, 102, 0.25)', borderWidth:'1px 0 0 0', }}/></Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>도움말 및 정보</Grid>
                        <Grid item xs={4}><hr style={{ borderColor: 'rgba(102, 102, 102, 0.25)', borderWidth:'1px 0 0 0' }}/></Grid>
                    </Grid>
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item xs={6}>
                            <div
                                onClick={() => window.open(`http://info.maumsensor.com/pages/contact/inquiry.php`)}
                                style={{ 
                                    textAlign: 'center', 
                                    height: '38px', 
                                    lineHeight: '38px', 
                                    borderRadius: '24.15px',
                                    border: '1px solid rgba(51, 51, 51, 1)',
                                    color: 'rgba(51, 51, 51, 1)',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                className='div_hover'
                            >
                                서비스 사용 / 제휴 문의
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div
                                style={{ 
                                    textAlign: 'center', 
                                    height: '38px', 
                                    lineHeight: '38px', 
                                    borderRadius: '24.15px',
                                    border: '1px solid rgba(51, 51, 51, 1)',
                                    color: 'rgba(51, 51, 51, 1)',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                className='div_hover'
                                onClick={landingpage}
                            >
                                마음센서 더 알아보기
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    xs={12} sm={12} md={6} lg={7} xl={8}
                    item
                >
                    <Hidden mdDown={true}>
                        <Grid container justifyContent={'center'}>
                            <Grid item md={12} lg={11} xl={10} style={{ padding: '3%'}}>
                                <h1 style={{ fontSize: '52px', color: 'rgba(99, 174, 99, 1)' }}>마음센서, 새로운 상담의 시작</h1>
                                <p style={{ fontSize: '28px', color: 'rgba(99, 140, 99, 1)'}}>국내 최초 주기적 결과 모니터링(Routine Outcome Monitoring)에 특화된 웹 서비스</p>
                                <p style={{ fontSize: '20px', color: 'rgba(78, 78, 78, 1)'}}>마음센서는 상담자들의 전문성 향상을 통해 보다 효과적인 심리 서비스를 제공할 수 있도록 도와 줍니다. 내담자의 변화와 성장을 데이터로 확인하고 새로운 상담을 경험해 보세요.</p>
                                <Button sx={{ padding: '12px 24px', height: '53px', width: '188px', borderRadius: '55px', fontSize: '20px' }} variant="contained" endIcon={<ArrowForwardIcon />} disableElevation onClick={landingpage}>
                                    더 알아보기
                                </Button>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        
        {props.searchEmail ?
        <AlertModal
            title='이메일 찾기'
            icon='success'
            open={openEmailFind}
            handleClose={handleCloseFindEmailModal}
        >
            이메일: {props.searchEmail}
        </AlertModal>:
        <FormModal
            open={openEmailFind}
            handleClose={handleCloseFindEmailModal}
            title='이메일 찾기'
            submitLabel='찾기'
            handleClickSubmit={props.handleClickSearchEmail}
        >
            <TextForm
                label='이름'
                placeholder='이름을 입력해주세요.'
                error={false}
                value={search.Name}
                handleChange={(e) => setSearch({ ...search, Name: e.target.value })}
            />
            <TextForm
                label='연락처'
                placeholder='연락처 입력해주세요.'
                error={false}
                value={search.Phone}
                handleChange={handleChangePhone}
            />
        </FormModal>}
        <FormModal
            open={openPasswordFind}
            handleClose={handleCloseFindPasswordModal}
            title='비밀번호 찾기'
            submitLabel='찾기'
            handleClickSubmit={() => {props.handleClickSearchPassword();}}
            disabled={searching}
        >
            <TextForm
                label='이름'
                placeholder='이름을 입력해주세요.'
                error={false}
                value={search.Name}
                handleChange={(e) => setSearch({ ...search, Name: e.target.value })}
            />
            <TextForm
                label='연락처'
                placeholder='연락처 입력해주세요.'
                error={false}
                value={search.Phone}
                handleChange={handleChangePhone}
            />
            <EmailForm
                label='이메일'
                error={false}
                value={search.Email}
                handleChange={(e) => setSearch({ ...search, Email: e.target.value })}
            />
        </FormModal>
        </>
    );
};

export default LoginView;