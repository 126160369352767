import React, { useEffect, useState } from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';

import { DetailContainer, ListContainer } from 'components/Items/Container';
import { DataBox } from 'components/Items/Table';
import { ProfileView, BasicView, SessionView, GoalView, ChartView, SummaryView } from './Session';
import { GenderKo, NationalityKo, MarriageKo, MilitaryKo, EmploymentKo, LivingKo, UserStatus } from 'components/Function/ChangeText';
import { PossibleDay, PossibleTime } from 'components/Function/AddDatas';

const SuperviseeDetailView = (props) => {
    const [tab, setTab] = useState(0);

    const datas = [
        { key: '이름', value: props.client.Counselor_id.Name },
        { key: '이메일', value: props.client.Counselor_id.Email },
        { key: '연락처', value: props.client.Counselor_id.Phone },
        { key: '권한', value: UserStatus(props.client.Counselor_id.Status) }
    ];

    const datas2 = [
        { key: '이름', value: props.client.Name },
        { key: '내담자ID', value: props.client.NickName },
        { key: '이메일', value: props.client.Email },
        { key: '연락처', value: props.client.Phone },
        { key: '비고', value: props.client.Memo, long: true },
        { key: '현 주소', value: props.client.Address },
        { key: '생년월일', value: props.client.Birth },
        { key: '성별', value: props.client.Gender !== 3 ? GenderKo(props.client.Gender) : props.client.GenderDetail },
        { key: '국적', value: props.client.Nationality !== 9 ? NationalityKo(props.client.Nationality) : props.client.NationalityDetail },
        { key: '종교', value: props.client.Religion },
        { key: '결혼유무', value: props.client.Marriage !== 6 ? MarriageKo(props.client.Marriage) : props.client.MarriageDetail },
        { key: '병역', value: MilitaryKo(props.client.Military) },
        { key: '최종학력', value: props.client.Education },
        { key: '직업', value: props.client.Employment !== 9 || !props.client.EmploymentDetail ? EmploymentKo(props.client.Employment) : props.client.EmploymentDetail },
        { key: '주거 형태', value: props.client.Living !== 6 ? LivingKo(props.client.Living) :props.client.LivingDetail },
        { key: '부양가족 수', value: props.client.Dependents || props.client.Dependents === 0 ? `${props.client.Dependents} 명` : '' },
        { key: '상담자', value: props.client.Counselor_id ? props.client.Counselor_id.Name : '' },
        { key: '신청일', value: props.client.CreatedAtValue }
    ];
    const [contentHeight, setContentHeight] = useState(220);
    const changeHeight = () => {
        setTimeout(() => {
            if(window.document.getElementById('endline')){
                setContentHeight(window.document.getElementById('endline').offsetTop-400);
            }else{
                setContentHeight(220);
            }
        }, [100]);
    };
    useEffect(() => {
        changeHeight();
    }, [props.client, tab]);

    const handleClickTab = (n) => {
        if(props.status === '1'){
            if(props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id){
                setTab(n);
            }else{
                return false;
            }
        }else{
            setTab(n);
        }
    };

    return (
        <>
        <DetailContainer
            title='상담자 정보'
        >
            <DataBox
                datas={datas}
            />
        </DetailContainer>
        <ListContainer h2={null}>
            <Grid container>
                <Grid item xs={12} style={{ position: 'relative', marginBottom: '94px' }}>
                    {/* 이름, 내담자 ID */}
                    <Grid style={{ position: 'absolute', right: '118px' }}>
                        <Grid container style={{ fontWeight: '700', fontSize: '14px', lineHeight: '16.8px' }}>
                            <Grid item style={{ backgroundColor: '#DEF4E4', padding: '6px 18px', borderRadius: '0px 0px 0px 15px' }}>이름</Grid>
                            <Grid item style={{ border: '1px solid #DEF4E4', padding: '6px 18px' }}>{props.client.Name}</Grid>
                            <Grid item style={{ backgroundColor: '#DEF4E4', padding: '6px 18px' }}>ID</Grid>
                            <Grid item style={{ border: '1px solid #DEF4E4', padding: '6px 18px', borderRadius: '0px 0px 15px 0px' }}>{props.client.NickName}</Grid>
                        </Grid>
                    </Grid>
                    {/* nav tap */}
                    <Grid style={{ position: 'absolute', top: '32px', left: '-10px' }}>
                        <Grid item container style={{ border: '2px solid #C6E7CF', borderBottom: '0px', borderRadius: '18px 18px 0px 0px', backgroundColor: '#C6E7CF' }}>
                            <Grid item className={ tab === 0 ? 'navtap tapchecked' : 'navtap'} onClick={() => setTab(0)}><div style={{ padding: '14px 16px' }}>프로필</div></Grid>
                            <Grid item className={ tab === 1 ? 'navtap tapchecked' : 'navtap'} onClick={() => setTab(1)}><div style={{ padding: '14px 16px' }}>기본서류</div></Grid>
                            <Grid item className={ tab === 2 ? 'navtap tapchecked' : 'navtap'} onClick={() => handleClickTab(2)}><div style={{ padding: '14px 16px' }}>상담(ROM)</div></Grid>
                            <Grid item className={ tab === 3 ? 'navtap tapchecked' : 'navtap'} onClick={() => handleClickTab(3)}><div style={{ padding: '14px 16px' }}>차트</div></Grid>
                            <Grid item className={ tab === 4 ? 'navtap tapchecked lasttap' : 'navtap lasttap'} onClick={() => handleClickTab(4)}><div style={{ padding: '14px 16px' }}>요약</div></Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid style={{ position: 'absolute', left: '360.13px', top: '33px', height: '48px', width: '34px', backgroundColor: '#C6E7CF'}}>
                        <Grid style={{ backgroundColor: '#FFF', width: '100%', height: '100%', borderRadius: '0px 0px 0px 18px' }}></Grid>
                    </Grid> */}
                    <Grid style={{ position: 'absolute', top: '81px', borderTop: '2px solid #C6E7CF', width: '100%', right: '-10px', height: tab === 0 ? `${contentHeight}px` : '37px', borderRadius: '0px 18px 0px 0px', borderRight: '2px solid #C6E7CF' }} />
                    <Grid style={{ position: 'absolute', left: '-10px', top: '81px', height: '37px', width: '2px' }} className='gradationline'/>
                    {/* <Grid style={{ position: 'absolute', right: '-10px', top: '81px', height: '37px', border: '1px solid #C6E7CF'}} /> */}
                </Grid>
                {/* <Grid item xs={12}>
                    <Box sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(e) => setTab(e.target.tabIndex)}>
                            <Tab label='프로필' tabIndex={0} sx={{ fontSize: '110%' }} />
                            <Tab label='기본서류' tabIndex={1} sx={{ fontSize: '110%' }} />
                            <Tab label='상담(ROM)' tabIndex={2} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                            <Tab label='목표' tabIndex={3}/>
                            <Tab label='차트' tabIndex={3} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                            <Tab label='요약' tabIndex={4} sx={{ fontSize: '110%' }} disabled={props.status === '1' ? props.client.Manager_id === props.client.Counselor_id._id || props.client.Manager_id === props.client.Counselor_id.Supervisor_id ? false : true : false} />
                        </Tabs>
                    </Box>
                </Grid> */}
                <Grid item xs={12} style={{ zIndex: 10 }}>
                    {
                    tab === 0 ?
                    <ProfileView 
                        client={props.client}
                        datas={datas2}
                        changeHeight={changeHeight}
                    /> :
                    tab === 1 ?
                    <BasicView 
                        client={props.client}
                        scales={props.client.Scales.filter((item) => (item.Scale_id.ScaleKind === 3))}
                    /> :
                    tab === 2 ? 
                    <SessionView 
                        client={props.client}
                        setClient={props.setClient}
                        selectSession={props.selectSession}
                        setSelectSession={props.setSelectSession}
                    /> :
                    // tab === 3 ?
                    // <GoalView 
                    //     goals={props.goals}
                    //     selectGoal={props.selectGoal}
                    //     setSelectGoal={props.setSelectGoal}
                    // /> :
                    tab === 3 ?
                    <ChartView 
                        client={props.client}
                        goals={props.goals}
                    /> :
                    <SummaryView 
                        goals={props.goals}
                        client={props.client}
                    />
                    } 
                </Grid>
            </Grid>
        </ListContainer>
        </>
    );
};

export default SuperviseeDetailView;