import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import { DetailContainer } from 'components/Items/Container';
import { DataBox, DataTable } from 'components/Items/Table';
import { SearchForm, SelectForm, DateForm, NumberForm, TextForm } from 'components/Items/Form';
import { ConfirmModal, DetailModal, FormModal } from 'components/Items/Modal';
import { YyyyMmDdHhMmSs, UserStateKo, YyyyMmDd } from 'components/Function/ChangeText';
import { useCookies } from 'react-cookie';
import { BillStatus } from 'components/Function/AddDatas';

const UserDetailView = (props) => {
    const navigate = useNavigate();
    const { userNo } = useParams();
    const [cookies] = useCookies(['Name']);
    
    const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);
    const [openUserConfirmModal, setOpenUserConfirmModal] = useState(false);

    const [openBillDetailModal, setOpenBillDetailModal] = useState(false);
    const [search, setSearch] = useState('');
    const [search2, setSearch2] = useState('');
    const keys = [
        { key: 'No', label: 'No.' },
        { key: 'Name', label: '이름' },
        { key: 'Email', label: '이메일' },
        { key: 'Phone', label: '연락처' },
        { key: 'StatusKo', label: '권한' }
    ];
    const keys2 = [
        { key: 'No', label: 'No.' },
        { key: 'PayAtValue', label: '기준일' },
        { key: 'CounselorCountValue', label: '상담자 수' },
        { key: 'RealPrice', label: '실제금액' },
        { key: 'Discount', label: '할인금액' },
        { key: 'CorrectionPrice', label: '수정금액' },
        { key: 'PayPrice', label: '결제금액' },
        { key: 'StatusKo', label: '결제여부' },
        { key: 'NextPayAtValue', label: '다음 결제일' }
    ];
    const { selectBill, setSelectBill, openBillCreateModal, setOpenBillCreateModal, openBillUpdateModal, setOpenBillUpdateModal } = props;
    const handleClickBill = (data) => {
        setSelectBill(data);
        setOpenBillDetailModal(true);
    };
    const handleCloseBillModal = () => {
        setOpenBillDetailModal(false);
        setOpenBillCreateModal(false);
        setOpenBillUpdateModal(false);
        setSelectBill({});
    };

    return (
        <>
        <DetailContainer
            title='유저 정보'
            icon={[ { type: 'delete', handleClick: () => setOpenUserDeleteModal(true) },
                    { type: 'update', handleClick: () => navigate(`/admin/user/${userNo}/update`) } ]}
            switch={props.manager.State !== 2 ? { label: props.manager.State === 1 ? <b>사용 가능</b> : <span style={{ color: '#5E5E5E'}}>사용 불가</span>, value: Boolean(props.manager.State === 1), handleChange: props.handleClickState, disabled: props.updating } : null}
            button={props.manager.State === 2 ? { label: '사용 확인', handleClick: () => setOpenUserConfirmModal(true) } : null}
        >
            <DataBox
                datas={[
                    { key: '이름', value: props.manager.Name },
                    { key: '연락처', value: props.manager.Phone },
                    { key: '이메일', value: props.manager.Email },
                    { key: '가입 일시', value: YyyyMmDdHhMmSs(props.manager.createdAt) },
                    { key: '상태', value: `사용 ${UserStateKo(props.manager.State)}` },
                    { key: '상담자수', value: `${props.manager.CounselorCount}명` }
                ]}
            />
        </DetailContainer>
        <DetailContainer
            title='결제 목록'
            button={{ label: '결제 등록', handleClick: () => setOpenBillCreateModal(true) }}
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search2}
                        handleChange={(e) => setSearch2(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys2}
                        datas={props.bills}
                        delete={false}
                        update={true}
                        handleClickUpdate={(data) => { setSelectBill(data);setOpenBillUpdateModal(true); }}
                        search={search2}
                        handleClickDetail={handleClickBill}
                    />
                </Grid>
            </Grid>
        </DetailContainer>
        <DetailContainer
            title='직원 목록'
        >
            <Grid container>
                <Grid item xs={12} md={6} style={{ marginBottom: '16px'}}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        keys={keys}
                        datas={props.workers}
                        delete={false}
                        update={false}
                        search={search}
                        handleClickDetail={() => { return false }}
                    />
                </Grid>
            </Grid>
        </DetailContainer>
        <ConfirmModal
            title='유저 삭제'
            icon='error'
            open={openUserDeleteModal}
            handleClose={() => {setOpenUserDeleteModal(false);}}
            handleClickSubmit={() => {setOpenUserDeleteModal(false);props.handleClickDeleteBtn();}}
            submitLabel='삭제'
        >
            유저 정보를 삭제 하시겠습니까? 유저 삭제 시 복구가 불가능합니다.
        </ConfirmModal>
        <ConfirmModal
            title='유저 사용 확인'
            icon='info'
            open={openUserConfirmModal}
            handleClose={() => {setOpenUserConfirmModal(false);}}
            handleClickSubmit={() => {setOpenUserConfirmModal(false);props.handleClickState();}}
            submitLabel='확인'
        >
            <b>{props.manager.Name}</b>의 상태를 "<b>사용 가능</b>"으로 변경합니다.
        </ConfirmModal>
        <FormModal
            title='결제 내역 등록'
            open={openBillCreateModal}
            handleClose={handleCloseBillModal}
            submitLabel={'등록'}
            disabled={!selectBill.PayAt || !selectBill.NextPayAt || !selectBill.Status}
            loading={props.loading}
            handleClickSubmit={props.handleClickBillCreateBtn}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextForm
                        label='대표관리자'
                        error={false}
                        value={props.manager.Name}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextForm
                        label='상담자 수'
                        error={false}
                        value={`${props.manager.CounselorCount}명`}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateForm
                        label='결제 기준일'
                        required={true}
                        error={false}
                        value={selectBill.PayAt}
                        handleChange={(e) => setSelectBill({ ...selectBill, PayAt: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateForm
                        label='다음 결제일'
                        required={true}
                        error={false}
                        value={selectBill.NextPayAt}
                        handleChange={(e) => setSelectBill({ ...selectBill, NextPayAt: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='실제 금액'
                        required={false}
                        error={false}
                        disabled={true}
                        placeholder=''
                        value={25000+15000*props.manager.CounselorCount}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='할인 금액'
                        required={false}
                        error={false}
                        placeholder=''
                        value={selectBill.Discount}
                        handleChange={(e) => setSelectBill({ ...selectBill, Discount: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='수정 금액'
                        required={false}
                        error={false}
                        disabled={true}
                        placeholder=''
                        value={selectBill.Discount ? 25000+15000*props.manager.CounselorCount - selectBill.Discount : 25000+15000*props.manager.CounselorCount}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='결제 금액'
                        required={false}
                        error={false}
                        placeholder=''
                        value={selectBill.PayPrice}
                        handleChange={(e) => setSelectBill({ ...selectBill, PayPrice: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='결제 상태'
                        required={true}
                        error={false}
                        value={selectBill.Status}
                        datas={BillStatus()}
                        handleChange={(e) => setSelectBill({ ...selectBill, Status: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextForm
                        label='수정인'
                        error={false}
                        value={cookies.Name}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextForm
                        label='메모'
                        error={false}
                        rows={4}
                        value={selectBill.Memo}
                        handleChange={(e) => setSelectBill({ ...selectBill, Memo: e.target.value })}
                    />
                </Grid>
            </Grid>
        </FormModal>
        <FormModal
            title='결제 내역 수정'
            open={openBillUpdateModal}
            handleClose={handleCloseBillModal}
            submitLabel={'수정'}
            disabled={!selectBill.PayAt || !selectBill.NextPayAt || !selectBill.Status}
            loading={props.loading}
            handleClickSubmit={props.handleClickBillUpdateBtn}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextForm
                        label='대표관리자'
                        error={false}
                        value={props.manager.Name}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextForm
                        label='상담자 수'
                        error={false}
                        value={`${selectBill.CounselorCount}명`}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateForm
                        label='결제 기준일'
                        required={true}
                        error={false}
                        value={YyyyMmDd(selectBill.PayAt)}
                        handleChange={(e) => setSelectBill({ ...selectBill, PayAt: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateForm
                        label='다음 결제일'
                        required={true}
                        error={false}
                        value={YyyyMmDd(selectBill.NextPayAt)}
                        handleChange={(e) => setSelectBill({ ...selectBill, NextPayAt: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='실제 금액'
                        required={false}
                        error={false}
                        disabled={true}
                        placeholder=''
                        value={selectBill.RealPrice}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='할인 금액'
                        required={false}
                        error={false}
                        placeholder=''
                        value={selectBill.Discount}
                        handleChange={(e) => setSelectBill({ ...selectBill, Discount: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='수정 금액'
                        required={false}
                        error={false}
                        disabled={true}
                        placeholder=''
                        value={selectBill.Discount ? 25000+15000*selectBill.CounselorCount - selectBill.Discount : 25000+15000*selectBill.CounselorCount}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberForm
                        label='결제 금액'
                        required={false}
                        error={false}
                        placeholder=''
                        value={selectBill.PayPrice}
                        handleChange={(e) => setSelectBill({ ...selectBill, PayPrice: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectForm
                        label='결제 상태'
                        required={true}
                        error={false}
                        value={selectBill.Status}
                        datas={BillStatus()}
                        handleChange={(e) => setSelectBill({ ...selectBill, Status: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextForm
                        label='수정인'
                        error={false}
                        value={cookies.Name === selectBill.UpdatedByName ? selectBill.UpdatedByName : `${selectBill.UpdatedByName} → ${cookies.Name}`}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextForm
                        label='메모'
                        error={false}
                        rows={4}
                        value={selectBill.Memo}
                        handleChange={(e) => setSelectBill({ ...selectBill, Memo: e.target.value })}
                    />
                </Grid>
            </Grid>
        </FormModal>
        <DetailModal
            title='결제 내역 상세 보기'
            open={openBillDetailModal}
            handleClose={handleCloseBillModal}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <p>대표관리자</p>
                    <div className='div_data_value'>{props.manager.Name}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>상담자 수</p>
                    <div className='div_data_value'>{selectBill.CounselorCountValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 기준일</p>
                    <div className='div_data_value'>{selectBill.PayAtValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>다음 결제일</p>
                    <div className='div_data_value'>{selectBill.NextPayAtValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>계산 요금</p>
                    <div className='div_data_value'>{selectBill.CorrectionPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>할인 요금</p>
                    <div className='div_data_value'>{selectBill.Discount}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>실제 요금</p>
                    <div className='div_data_value'>{selectBill.RealPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 요금</p>
                    <div className='div_data_value'>{selectBill.PayPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 상태</p>
                    <div className='div_data_value'>{selectBill.StatusKo}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>수정인</p>
                    <div className='div_data_value'>{selectBill.UpdatedByName}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>메모</p>
                    <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectBill.Memo ? selectBill.Memo.replaceAll('\n', '<br/>') : 'ㅤ' }}/>
                </Grid>
            </Grid>
        </DetailModal>
        </>
    );
};

export default UserDetailView;