import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { DataTable } from 'components/Items/Table';
import { ConfirmModal } from 'components/Items/Modal';

const UserListView = (props) => {
    const navigate = useNavigate();
    const keys = [{ key: 'No', label: 'No.'},
                        { key: 'Name', label: '이름'},
                        { key: 'Phone', label: '연락처'},
                        { key: 'Email', label: '이메일'},
                        { key: 'StateValue', label: '상태'}, 
                        { key: 'CreatedAtValue', label: '가입 일시'}];

    const [search, setSearch] = useState('');
    const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);

    const handleClickDetail = (data) => {
        navigate(`/admin/user/${data.UserNo}`);
    };
    const handleClickUpdate = (data) => {
        navigate(`/admin/user/${data.UserNo}/update`);
    };
    const handleClickDelete = (data) => {
        props.setSelectManager(data);
        setOpenUserDeleteModal(true);
    };

    return (
        <>
        <ListContainer title='유저 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid item sm={12} md={5} style={{ marginBottom: '16px' }}>
                    <SearchForm
                        value={search}
                        handleChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid container item sm={12} md={7} alignItems={'center'} style={{ marginBottom: '16px' }}>
                    
                </Grid>
            </Grid>
            <Grid>
                <DataTable
                    keys={keys}
                    datas={props.managers}
                    delete={true}
                    update={true}
                    search={search}
                    handleClickDetail={handleClickDetail}
                    handleClickUpdate={handleClickUpdate}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
        </ListContainer>
        {props.selectManager._id ? 
        <ConfirmModal
            title='유저 삭제'
            icon='error'
            open={openUserDeleteModal}
            handleClose={() => setOpenUserDeleteModal(false)}
            handleClickSubmit={() => {setOpenUserDeleteModal(false);props.handleClickDeleteBtn();}}
            submitLabel='삭제'
        >
            유저 정보를 삭제 하시겠습니까? 유저 삭제 시 복구가 불가능합니다.
        </ConfirmModal>:null}
        </>
    );
};

export default UserListView;