import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { BillList } from 'domains/Admin/Bill';

const BillRoute = () => {
    return (
        <Routes>
            <Route path='' element={<BillList />}/>
        </Routes>
    );
};

export default BillRoute;