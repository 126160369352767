import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { DashboardLayout } from 'components/Layout';
import { BillListView } from 'components/Admin/Bill';
import { BillStateKo, YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const BillListContainer = (props) => {
    const pages = [
        { name: '결제', link: '' },
        { name: '결제 목록', link: '' }
    ];
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [bills, setBills] = useState([]);
    const [selectBill, setSelectBill] = useState({});

    const handleClickUpdateBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(selectBill);
        await axios.put(`/api/admin/contact/${selectBill.BillNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/bill/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadBills = [...res.data.BillDatas];
                for(let i = 0; i < loadBills.length; i++){
                    loadBills[i].No = i + 1;
                    loadBills[i].ManagerName = loadBills[i].Manager_id.Name;
                    loadBills[i].UpdatedByName = loadBills[i].UpdatedBy_id.Name;
                    loadBills[i].StartDate = YyyyMmDd(loadBills[i].PayAt);
                    loadBills[i].StatusValue = BillStateKo(loadBills[i].Status);
                    loadBills[i].NextDate = YyyyMmDd(loadBills[i].NextPayAt);
                    loadBills[i].CounselorCountValue = `${loadBills[i].CounselorCount}명`;
                }
                setBills(loadBills);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            console.log(err);
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, []);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <BillListView
                bills={bills}
                selectBill={selectBill}
                setSelectBill={setSelectBill}
                handleClickUpdateBtn={handleClickUpdateBtn}
            />
        </DashboardLayout>
    );
};

export default BillListContainer;