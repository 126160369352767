import React, { useEffect, useState } from 'react';
import { Grid, OutlinedInput, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { useSnackbar } from 'notistack';

import { ListContainer } from 'components/Items/Container';
import { SearchForm } from 'components/Items/Form';
import { SmallSubmitButton, TooltipButton } from 'components/Items/Button';
import { DataTableSort } from 'components/Items/Table';
import { YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';
import { FormModal } from 'components/Items/Modal';

const BillListView = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const keys = [
        { key: 'No', label: 'No.'},
        { key: 'ManagerName', label: '대표관리자'},
        { key: 'StartDate', label: '결제기준일'},
        { key: 'CounselorCountValue', label: '상담자수', align: 'right' },
        { key: 'CorrectionPrice', label: '실제금액', align: 'right' },
        { key: 'Discount', label: '할인금액', align: 'right' },
        { key: 'RealPrice', label: '수정금액', align: 'right' },
        { key: 'PayPrice', label: '결제금액', align: 'right' },
        { key: 'StatusValue', label: '상태'},
        { key: 'NextDate', label: '다음결제일'},
        { key: 'UpdatedByName', label: '수정인'},
    ];
    const selectBill = props.selectBill;
    const [search, setSearch] = useState('');
    const [sumPay, setSumPay] = useState(0);
    const [filterDate, setFilterDate] = useState([YyyyMmDd(new Date(new Date().setDate(1))),null]);
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openBillDetailModal, setOpenBillDetailModal] = useState(false);

    const handleClickDownload = () => {
        setLoading(true);
        const rowData = filterDatas(props.bills);
        let csvData = [
            ['No.', '대표관리자', '결제기준일', '상담자수', '실제금액', '할인금액', '수정금액', '결제금액', '상태', '다음결제일', '수정인']
        ];
      
        for(let i = 0; i < rowData.length; i++){
            csvData.push([
                rowData[i].No, 
                rowData[i].Manager_id?.Name, 
                rowData[i].StartDate, 
                rowData[i].CounselorCountValue, 
                rowData[i].CorrectionPrice, 
                rowData[i].Discount, 
                rowData[i].RealPrice, 
                rowData[i].PayPrice, 
                rowData[i].StatusValue, 
                rowData[i].NextDate,
                rowData[i].UpdatedByName
            ]);
        }
        setCsvData(csvData);
        setTimeout(() => {
            clickLink();
            setLoading(false);
            enqueueSnackbar('다운로드가 완료되었습니다.', { variant: 'info' });
        }, [2000]);
    };
    const clickLink = () => {
        window.document.getElementById('csv_download').click();
    };

    const handleClickDetail = (data) => {
        props.setSelectBill(data);
        setOpenBillDetailModal(true);
    };

    const filterDatas = (datas) => {
        let tumpData = [...datas];
        if(filterDate[0]){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) >= new Date(filterDate[0])));
        }
        if(filterDate[1]){
            tumpData = tumpData.filter((a) => (new Date(a.StartDate) <= new Date(`${filterDate[1]} 23:59:59`)));
        }
        if(tumpData){
            return tumpData.filter((item) => {
                let returnData = false;
                for(let i = 0; i < keys.length; i++){
                    if(item[`${keys[i].key}`]?.toString().indexOf(search) > -1){
                        returnData = true;
                        break;                    
                    }
                }
                return returnData;
            });
        }else{
            return [];
        }
    };
    // 필터 후 총액 계산
    useEffect(() => {
        const datas = filterDatas(props.bills);
        let sum = 0;
        for(let i = 0; i < datas.length; i++){
            if(datas[i].Status === 1){
                sum += datas[i].PayPrice ? Number(datas[i].PayPrice) : 0;
            }
        }
        setSumPay(sum);
    }, [search, filterDate, props.bills]);

    return (
        <>
        <ListContainer title='결제 목록'>
            <Grid container justifyContent={'space-between'}>
                <Grid container item sm={12} md={8} lg={8} style={{ marginBottom: '16px', paddingRight: '8px' }} spacing={1}>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <SearchForm
                            value={search}
                            handleChange={(e) => setSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item container justifyContent={'space-between'} alignItems={'center'} xs={12} sm={12} md={6} lg={6}>
                        <Grid item xs={5.5}>
                            <OutlinedInput
                                type={'date'}
                                size='small'
                                fullWidth
                                sx={{ mr: 1 }}
                                value={filterDate[0]}
                                onChange={(e) => setFilterDate([e.target.value, filterDate[1]])}
                            />
                        </Grid>
                        <Grid item xs={0.1} sx={{ textAlign: 'center' }}>
                            ~
                        </Grid>
                        <Grid item xs={5.5}>
                            <OutlinedInput
                                type={'date'}
                                size='small'
                                fullWidth
                                value={filterDate[1]}
                                onChange={(e) => setFilterDate([filterDate[0], e.target.value])}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sm={12} md={4} lg={4} alignItems={'flex-end'} style={{ marginBottom: '16px' }}>
                    <Grid item xs={12} container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            <SmallSubmitButton label='다운로드' handleClick={handleClickDownload} loading={loading} />
                        </Grid>
                        {/* <Grid item>
                            <SmallSubmitButton  label='PDF' handleClick={() => window.open(`${window.location.origin}/table/${search ? search : 'null'}/${filterDate[0] ? filterDate[0] : 'null'}/${filterDate[1] ? filterDate[1] : 'null'}/${counselorId ? counselorId : 'null'}`)} />
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <DataTableSort
                    keys={keys}
                    datas={filterDatas(props.bills)}
                    delete={false}
                    update={false}
                    nonePage={true}
                    search={search}
                    defaultSort={[180, 0]}
                    handleClickDetail={handleClickDetail}
                />
            </Grid>
            <Grid container justifyContent={'flex-end'} alignItems={'center'} sx={{ mt: 1 }}>
                <Grid item xs={9} md={3}>
                    <Grid
                        container
                        style={{
                            borderRadius: '5px',
                            border: '1px solid #C0C0C0'
                        }}
                        alignItems={'center'}
                    >
                        <Grid
                            item 
                            xs={4} sm={3} 
                            style={{ 
                                backgroundColor: '#D9D9D9', 
                                textAlign: 'center', 
                                fontWeight: 'bold', 
                                borderRadius: '5px 0px 0px 5px', 
                                padding: '4px 16px', 
                                overflow: 'hidden', 
                                whiteSpace: 'nowrap', 
                                textOverflow: 'ellipsis'
                            }}
                        >
                            합계
                        </Grid>
                        <Grid 
                            item
                            xs={8} sm={9}
                            style={{
                                paddingLeft: '16px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {sumPay.toLocaleString()}원
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ListContainer>
        <FormModal
            title='결제 내역 상세 보기'
            open={openBillDetailModal}
            handleClose={() => setOpenBillDetailModal(false)}
            handleClickSubmit={() => navigate(`/admin/user/${selectBill.Manager_id.UserNo}`)}
            submitLabel={'바로가기'}
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <p>대표관리자</p>
                    <div className='div_data_value'>{selectBill.Manager_id?.Name}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>상담자 수</p>
                    <div className='div_data_value'>{selectBill.CounselorCountValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 기준일</p>
                    <div className='div_data_value'>{selectBill.StartDate}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>다음 결제일</p>
                    <div className='div_data_value'>{selectBill.NextDate}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>실제 금액</p>
                    <div className='div_data_value'>{selectBill.CorrectionPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>할인 금액</p>
                    <div className='div_data_value'>{selectBill.Discount}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>수정 금액</p>
                    <div className='div_data_value'>{selectBill.RealPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 금액</p>
                    <div className='div_data_value'>{selectBill.PayPrice}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>결제 상태</p>
                    <div className='div_data_value'>{selectBill.StatusValue}</div>
                </Grid>
                <Grid item xs={6}>
                    <p>수정인</p>
                    <div className='div_data_value'>{selectBill.UpdatedByName}</div>
                </Grid>
                <Grid item xs={12}>
                    <p>메모</p>
                    <div className='div_data_value' dangerouslySetInnerHTML={{ __html: selectBill.Memo ? selectBill.Memo.replaceAll('\n', '<br/>') : 'ㅤ' }}/>
                </Grid>
            </Grid>
        </FormModal>
        <CSVLink style={{ display: 'none' }} data={csvData} separator=',' id='csv_download' filename={`download_${YyyyMmDdHhMmSs(new Date())}`}>Download me</CSVLink>
        </>
    );
};

export default BillListView;