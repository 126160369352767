import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { DashboardLayout } from 'components/Layout';
import { UserDetailView } from 'components/Admin/User';
import { BillStateKo, MemberStatus, YyyyMmDd, YyyyMmDdHhMmSs } from 'components/Function/ChangeText';

const UserDetailContainer = (props) => {
    const { userNo } = useParams();
    const pages = [
        { name: '유저', link: '/admin/user' },
        { name: '유저 상세', link: '' }
    ];
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const [updating, setUpdating] = useState(false);
    const [manager, setManager] = useState({});
    const [workers, setWorkers] = useState([]);
    const [bills, setBills] = useState([]);
    const [selectBill, setSelectBill] = useState({});
    const [loading, setLoading] = useState(false);
    const [openBillCreateModal, setOpenBillCreateModal] = useState(false);
    const [openBillUpdateModal, setOpenBillUpdateModal] = useState(false);

    const handleClickState = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(manager);
        await axios.patch(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setManager({ ...manager, State: manager.State === 1 ? 3 : 1 });
                setTimeout(() => {
                    enqueueSnackbar(res.data.Message, { variant: 'success' });
                    setUpdating(false);
                }, [1200]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/admin/manager/${manager._id}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                navigate(-1);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickBillCreateBtn = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = {
            Manager_id: manager._id,
            CounselorCount: manager.CounselorCount,
            PayAt: selectBill.PayAt,
            NextPayAt: selectBill.NextPayAt,
            RealPrice: 25000+15000*manager.CounselorCount,
            Discount: selectBill.Discount,
            CorrectionPrice: selectBill.Discount ? 25000+15000*manager.CounselorCount - selectBill.Discount : 25000+15000*manager.CounselorCount,
            PayPrice: selectBill.PayPrice,
            Status: selectBill.Status,
            Memo: selectBill.Memo
        };
        await axios.post(`/api/admin/bill/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
                setTimeout(() => {
                    setLoading(false);
                    setSelectBill({});
                    setOpenBillCreateModal(false);
                }, [500]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const handleClickBillUpdateBtn = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = {
            PayAt: selectBill.PayAt,
            NextPayAt: selectBill.NextPayAt,
            Discount: selectBill.Discount,
            CorrectionPrice: selectBill.Discount ? selectBill.RealPrice - selectBill.Discount : selectBill.RealPrice,
            PayPrice: selectBill.PayPrice,
            Status: selectBill.Status,
            Memo: selectBill.Memo
        };
        await axios.put(`/api/admin/bill/${selectBill.BillNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                loadDatas();
                setTimeout(() => {
                    setLoading(false);
                    setSelectBill({});
                    setOpenBillUpdateModal(false);
                }, [500]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/${userNo}/${cookies.LoginKey}`, config)
        .then(async(res) => {
            if(!res.data.ErrorMessage){
                await axios.get(`/api/admin/manager/employee/${res.data.ManagerData._id}/${cookies.LoginKey}`, config)
                .then((res2) => {
                    if(!res2.data.ErrorMessage){
                        let loadWorkers = [...res2.data.WorkerDatas];
                        for(let i = 0; i < loadWorkers.length; i++){
                            loadWorkers[i].No = i + 1;
                            loadWorkers[i].StatusKo = MemberStatus(loadWorkers[i].Status);
                        }
                        let loadManager = { ...res.data.ManagerData };
                        loadManager.CounselorCount = loadWorkers.filter((item) => (item.Status === 2)).length;
                        setManager(loadManager);
                        setWorkers(loadWorkers);
                        let loadBills = [...res.data.BillDatas];
                        for(let i = 0; i < loadBills.length; i++){
                            loadBills[i].No = i + 1;
                            loadBills[i].StatusKo = BillStateKo(loadBills[i].Status);
                            loadBills[i].PayAtValue = YyyyMmDd(loadBills[i].PayAt);
                            loadBills[i].NextPayAtValue = YyyyMmDd(loadBills[i].NextPayAt);
                            loadBills[i].ManagerName = loadBills[i].Manager_id.Name;
                            loadBills[i].UpdatedByName = loadBills[i].UpdatedBy_id.Name;
                            loadBills[i].CounselorCountValue = `${loadBills[i].CounselorCount}명`;
                        }
                        setBills(loadBills);
                    }else{
                        enqueueSnackbar(res2.data.ErrorMessage, { variant: 'error' });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar('Network Error!', { variant: 'error' });
                    console.log(err);
                });
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            console.log(err);
        });
    }, [cookies.LoginKey, enqueueSnackbar, setManager, userNo, setWorkers]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <DashboardLayout pages={pages} status={props.status}>
            <UserDetailView 
                manager={manager}
                workers={workers}
                bills={bills}
                updating={updating}
                selectBill={selectBill}
                loading={loading}
                setSelectBill={setSelectBill}
                handleClickState={handleClickState}
                handleClickDeleteBtn={handleClickDeleteBtn}
                handleClickBillCreateBtn={handleClickBillCreateBtn}
                handleClickBillUpdateBtn={handleClickBillUpdateBtn}
                openBillCreateModal={openBillCreateModal}
                setOpenBillCreateModal={setOpenBillCreateModal}
                openBillUpdateModal={openBillUpdateModal}
                setOpenBillUpdateModal={setOpenBillUpdateModal}
            />
        </DashboardLayout>
    );
};

export default UserDetailContainer;